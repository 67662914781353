.leaflet-pane {
  z-index: 4;
}

.leaflet-tile-pane {
  z-index: 2;
}
.leaflet-overlay-pane {
  z-index: 4;
}
.leaflet-shadow-pane {
  z-index: 5;
}
.leaflet-marker-pane {
  z-index: 6;
}
.leaflet-tooltip-pane {
  z-index: 7;
}
.leaflet-popup-pane {
  z-index: 8;
}

.leaflet-map-pane canvas {
  z-index: 1;
}
.leaflet-map-pane svg {
  z-index: 2;
}
.leaflet-control {
  z-index: 8;
}
